export const COMPANY_ID = ['LPE-NVNJXV', 'LPE-0ET1I1'];

export interface SlabCheckCompany {
    id: string;
    slabs: number;
}

export const COMPANY_ID_ESCP = ['10b82559-f48f-41fd-8388-0af97c8fbd3f'];
export const SLAB_CHECK_COMPANY_ID: SlabCheckCompany[] = [
    { id: 'LPE-ATM46E', slabs: 1000000 },
    { id: '23', slabs: 1000000 },
    { id: 'LPE-FUQFEK', slabs: 1000000 },
    { id: 'LPE-9GU1DC', slabs: 750000 },
    { id: 'LPE-2CXQA2', slabs: 1000000 },
    { id: 'LPE-UV0DJB', slabs: 1000000 },
    { id: 'LPE-P7QX5Z', slabs: 750000 }
];

export const DO_NOT_DELETE_MEMBERS = ['LPE-O83TJO', 'LPE-F3EZO9'];
